













































import Base from '@/mixins/Base.vue';
import { IBlogPost, IBlogPostResponse } from '@/interfaces/blogpost';

const component = Base.extend({
    data() {
        return {
            active: false,
            confirmation: '',
            blogPost: undefined as any | IBlogPost,
        };
    },
    computed: {
        validated() : boolean {
            return this.confirmation === this.blogPost.title.toLowerCase().substr(0, 5);
        },
    },
    mounted() {
        this.active = true;
        this.getBlogPost();
    },
    methods: {
        getBlogPost(): void {
            this.get<IBlogPostResponse>(`blog/posts/${this.$route.params.id}`).then(({ data }) => {
                this.blogPost = data.data;
            });
        },
        save(): void {
            if (this.validated) {
                this.delete(`blog/posts/${this.blogPost.id}`).then(() => {
                    this.$router.push({ name: 'blog.posts.list' });
                    this.$emit('reload');
                });
            }
        },
    },
});

export default component;
